import React, { useState, useEffect } from 'react';
import '../style/Marquee.css';

export default function Marquee({ phrase, direction, repeat }) {

const [position, setPosition] = useState(0);

useEffect(() => {
    const interval = setInterval(() => {
        if (direction === 'left') {
        setPosition(position => position - 1);
        } else if (direction === 'right') {
        setPosition(position => position + 1);
        } else if (direction === 'up') {
        setPosition(position => position - 1);
        } else if (direction === 'down') {
        setPosition(position => position + 1);
        }
    }, 16);

    return () => clearInterval(interval);
}, [direction]);

return (
    <div className='enable-animation'>
        <div className="marquee">
            <ul className="marquee__content">
                <div className=" marquee__item">
                    "INSERT INSPIRATIONAL QUOTE"
                </div>
                <div className=" marquee__item">
                    "INSERT INSPIRATIONAL QUOTE"
                </div>
                <div className=" marquee__item">
                    "INSERT INSPIRATIONAL QUOTE"
                </div>
                <div className=" marquee__item">
                    "INSERT INSPIRATIONAL QUOTE"
                </div>
                <div className=" marquee__item">
                    "INSERT INSPIRATIONAL QUOTE"
                </div>
                <div className=" marquee__item">
                    "INSERT INSPIRATIONAL QUOTE"
                </div>
            </ul>

            <ul aria-hidden="true" className="marquee__content">
                <div className=" marquee__item">
                    "INSERT INSPIRATIONAL QUOTE"
                </div>
                <div className=" marquee__item">
                    "INSERT INSPIRATIONAL QUOTE"
                </div>
                <div className=" marquee__item">
                    "INSERT INSPIRATIONAL QUOTE"
                </div>
                <div className=" marquee__item">
                    "INSERT INSPIRATIONAL QUOTE"
                </div>
                <div className=" marquee__item">
                    "INSERT INSPIRATIONAL QUOTE"
                </div>
                <div className=" marquee__item">
                    "INSERT INSPIRATIONAL QUOTE"
                </div>
            </ul>
        </div>
    </div>
);

}

  
/*
<ul className="marquee__content">
    <div className=" marquee__item">
        WORK HARD.
    </div>
    <div className=" marquee__item">
        PLAY HARD.
    </div>
    <div className=" marquee__item">
        WORK HARD.
    </div>
    <div className=" marquee__item">
        PLAY HARD.
    </div>
    <div className=" marquee__item">
        WORK HARD.
    </div>
    <div className=" marquee__item">
        PLAY HARD.
    </div>
    <div className=" marquee__item">
        WORK HARD.
    </div>
    <div className=" marquee__item">
        PLAY HARD.
    </div>
    <div className=" marquee__item">
        WORK HARD.
    </div>
</ul>

<ul aria-hidden="true" className="marquee__content">
    <div className=" marquee__item">
        PLAY HARD.
    </div>
    <div className=" marquee__item">
        WORK HARD.
    </div>
    <div className=" marquee__item">
        PLAY HARD.
    </div>
    <div className=" marquee__item">
        WORK HARD.
    </div>
    <div className=" marquee__item">
        PLAY HARD.
    </div>
    <div className=" marquee__item">
        WORK HARD.
    </div>
    <div className=" marquee__item">
        PLAY HARD.
    </div>
    <div className=" marquee__item">
        WORK HARD.
    </div>
    <div className=" marquee__item">
        PLAY HARD.
    </div>
</ul>
*/