import React from 'react';
import './../style/LineBreaker.css';

export default function LineBreaker(props) {

    const style = {width: props.width ? props.width : '100%'};

    return (
        <div className='line-breaker' style={style}></div>
    )
}