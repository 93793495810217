import React, { useState } from 'react';
import BoxAnimated from '../components/BoxAnimated';
import './../style/Education.css'
import EducationListItem from '../components/EducationListItem';
import {motion} from 'framer-motion';

export default function Education(props) {

    const imagePaths = [
        <span style={{color:'#55a373'}}>Greenbury Secondary</span>,
        <span style={{color:'#014b9d'}}>Richfield</span>,
        <span style={{color:'#014b9d'}}>Richfield</span>
    ];
    const educationInfo = [
        'During my high school years at Greebury Secondary, I actively participated in soccer while excelling academically. I focused on IT, accounting, business, and higher grade mathematics, laying a strong foundation for my future in the IT field. In recognition of my efforts and achievements, I received a sports award and was an active member of the Representative Council of Learners (RCL) and the Student School Governing Body. These experiences not only enhanced my leadership and teamwork skills but also prepared me for the challenges and opportunities in my professional journey.',
        'At Richfield College, I pursued a three-year diploma in IT. My studies encompassed programming principles, network engineering, cyber security, and systems engineering techniques. This comprehensive curriculum equipped me with a broad and solid understanding of the IT landscape.',
        'Continuing my education at Richfield College, I dedicated an additional year to further build upon the concepts I had previously learned. This effort culminated in obtaining a bachelor\'s degree in IT, which I passed with flying colors. This achievement reflects my deep commitment and passion for the field.'
    ];
    const positions = [1,2,3,4,5,6];
    const [position, setPosition] = useState(1);
    const [educationDetailText, setEducationDetailText] = useState(educationInfo[0]);
    const [educationDetailImage, setEducationDetailImage] = useState(imagePaths[0])
    const[changePosition,setChangePosition] = useState(false);

    const handleEducationClick = (event) => {
        let educationNumber = event.currentTarget.dataset.number;
        setEducationDetailText(educationInfo[educationNumber - 1])
        setEducationDetailImage(imagePaths[educationNumber - 1])
        setChangePosition(true);
    };

    const executeCoolAnimation = (event)=>{
        let newPosition;
        do {
            newPosition = positions[Math.floor(Math.random() * positions.length)];
        } while (newPosition === position);

        console.log("New Position",newPosition)

        setPosition(newPosition);
    }

    return (
        <section id="education">
            <div id="education--top">
                <div id="education--list">
                    <EducationListItem
                    initial={props.slideAnimation('left').initial} whileInView={props.slideAnimation('left').whileInView}
                    onclick={(event) => handleEducationClick(event)} onhover={(event)=>{executeCoolAnimation(event)}} number="1" school="Greenbury Secondary" course="National Senior Cerificate" year="2015 - 2019" />
                    <EducationListItem
                    initial={props.slideAnimation('left').initial} whileInView={props.slideAnimation('left').whileInView}
                    onclick={(event) => handleEducationClick(event)} onhover={(event)=>{executeCoolAnimation(event)}} number="2" school="Richfield" course="Diploma in IT" year="2020 - 2023" />
                    <EducationListItem
                    initial={props.slideAnimation('left').initial} whileInView={props.slideAnimation('left').whileInView}
                    onclick={(event) => handleEducationClick(event)} onhover={(event)=>{executeCoolAnimation(event)}} number="3" school="Richfield" course="Bachelors Degree in IT" year="2023 - 2024" />
                </div>
                <BoxAnimated initial={props.slideAnimation('right').initial} whileInView={props.slideAnimation('right').whileInView} position={position} />
            </div>
            <div id="education--details">
                <motion.div initial={props.slideAnimation('left').initial} whileInView={props.slideAnimation('left').whileInView} id="education--institution-img">
                    {educationDetailImage}
                </motion.div>
                <motion.div initial={props.slideAnimation('right').initial} whileInView={props.slideAnimation('right').whileInView} id="education--info">
                    {educationDetailText}
                </motion.div>
            </div>

        </section>
    )
}