import React from 'react';
import SkillListItem from '../components/SkillListItem';

export default function SkillsList(props) {

    if (props.skills == undefined) throw new Error("SkillsList requires a skills prop")

    // Build list markup
    const skillsList = props.skills.map((skill) => {
        return (
            <SkillListItem skillText={skill} />
        )
    })

    return (
        <div className="skills-list-container font-jetbrains">
            {skillsList}
        </div>
    )
}