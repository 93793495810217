import React, { useState } from 'react';
import './../style/Experience.css';
import ExperienceView from '../islands/ExperienceView';
import ExperienceDetailed from '../islands/ExperienceDetailed';

export default function Experience(props) {

    const [showDetailedExperience, setShowDetailedExperience] = useState(false);
    const [detailIndex, setDetailIndex] = useState(0);

    const viewExperience = (detail)=>{
        switch (detail) {
            case "Software Developer Intern-Synaps Labs":
                setDetailIndex(1);
                setShowDetailedExperience(true);
                window.location.href = "#experience-detailed";
            break;

            case "Cyber Security Intern-Netsurit":
                setDetailIndex(2);
                setShowDetailedExperience(true);
                window.location.href = "#experience-detailed";
            break;
        
            default:
                console.log("Experience",detail)
                break;
        }
    }

    const exitDetailedExperience = ()=>{
        setShowDetailedExperience(false);
        window.location.href = "#experience";
    }

    return (
        <>
            {
                showDetailedExperience ?
                <ExperienceDetailed slideAnimation={props.slideAnimation} index={detailIndex} exitExperience={exitDetailedExperience} /> :
                <ExperienceView slideAnimation={props.slideAnimation} experienceClick={(event)=>viewExperience(event)} />
            }
        </>
    )
}