import React from 'react';
import './../style/Skills.css'
import SkillsList from '../islands/SkillsList';
import {motion} from 'framer-motion';

export default function Skills(props) {
    return (
        <section id="skills">
            <div id="skills-wrapper">
                <div id="skills-intro">
                    <motion.div initial={props.slideAnimation('left').initial} whileInView={props.slideAnimation('left').whileInView} id="intro--left">
                        <div className="intro--left--word">
                            <span>SKILLS</span><span>.</span>
                        </div>
                        <div className="intro--left--word">
                            <span>ARE</span><span>.</span>
                        </div>
                        <div className="intro--left--word">
                            <span>EVERYTHING</span><span>.</span>
                        </div>
                    </motion.div>
                    <motion.div initial={props.slideAnimation('right').initial} whileInView={props.slideAnimation('right').whileInView} id="intro--right">
                        <p>
                        The IT industry is ever-changing. Skills alone simply won't do the trick. The ability to learn skills, and more specifically - to learn skills quickly, will. Throughout my educational and professional careers, I have gained many learned many skills from many experienced individuals. In my day to day life I do my very best to use the skills that I have learned and push myself to always learn more.
                        </p>
                    </motion.div>
                </div>
                <div id="skills-list">
                    <motion.div initial={props.slideAnimation('left').initial} whileInView={props.slideAnimation('left').whileInView} className="skills-list--languages">
                        <h3>Programming Languages / Frameworks</h3>
                        <SkillsList skills={['HTML/CSS','JavaScript','Java','C#','C++','Dart','Flutter']} />
                    </motion.div>
                    <motion.div initial={props.slideAnimation('left').initial} whileInView={props.slideAnimation('left').whileInView} className="skills-list--database">
                        <h3>Database</h3>
                        <SkillsList skills={['MySQL']} />
                    </motion.div>
                    <motion.div initial={props.slideAnimation('left').initial} whileInView={props.slideAnimation('left').whileInView} className="skills-list--server">
                        <h3>Server / Cloud</h3>
                        <SkillsList skills={['Ubuntu','Netlify']} />
                    </motion.div>
                </div>
            </div>
        </section>
    )
}