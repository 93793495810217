import React from 'react';
import Socials from '../islands/Socials';
import LineBreaker from '../components/LineBreaker';
import ScrollDownBtn from '../components/ScrollDownBtn';
import './../style/Hero.css';
import {motion} from 'framer-motion';
import {slideAnimation} from './../util/JsAnimations'

export default function Hero(){

  return (
    <motion.section id="hero">
        <motion.div id="hero-left"> {/* Add padding:10px */}
            {/* Add border-left 1px solid white and padding: 10px */}
            <motion.div id="hero-left--info">
                <motion.div id="hero-left--head" initial={slideAnimation('left').initial} whileInView={slideAnimation('left').whileInView}>
                    <div id="profile-img-mobile"></div>
                    <div id="hero--qualification" className="qualification">Bsc. IT - Richfield</div>
                    <div id="hero--name" className="name">Jiveshen Govender</div>
                    <LineBreaker />
                    <div id="hero--title" className="title">IT Specialist</div>
                </motion.div>
                <motion.div id="hero-left--quote" initial={slideAnimation('left').initial} whileInView={slideAnimation('left').whileInView} exit={slideAnimation('left').exit}>
                    "Knowledge is the key to success.<br/>
                    Hard work and perseverance is the door"
                </motion.div>
                <motion.div id="hero-left--contact" initial={slideAnimation('left').initial} whileInView={slideAnimation('left').whileInView} exit={slideAnimation('left').exit}>
                    <div><a href="mailto:jiveshengovender10@gmail.com">jiveshengovender10@gmail.com</a></div>
                        {/* <LineBreaker width="50%" /> */}
                    <div><a href="tel:+27634356990">(063)-435-6990</a></div>
                </motion.div>
            </motion.div>
        </motion.div>
        <div id="hero-right">
            <div id="hero-right--head">
                <Socials align_left={false} initial={slideAnimation('right').initial} whileInView={slideAnimation('right').whileInView} exit={slideAnimation('right').exit} />
            </div>
        </div>
        <ScrollDownBtn scrollToID="about" />
        
    </motion.section>
  )
}