import React from 'react';
import {motion} from 'framer-motion';

export default function EducationListItem(props) {

    if(!props.number) throw new Error("number is required");
    if(!props.school) throw new Error("school is required");
    if(!props.course) throw new Error("course is required");
    if(!props.year) throw new Error("year is required");
    if(!props.onclick) throw new Error("onclick handler is required");
    if(!props.onhover) throw new Error("onhover handler is required");

    return (
        <motion.div
        initial={props.initial} whileInView={props.whileInView}
         className="education--list-item" title="Click Me" data-number={props.number} onClick={(event)=>props.onclick(event)} onMouseOver={(event)=>{props.onhover(event)}}>
            <div className="list-item--number">
                <span>{props.number}</span>
            </div>
            <div className="list-details">
                <div className="list-details--school">
                    <span>{props.school}</span>
                </div>
                <div className="list-details--course">
                    <span>{props.course}</span>
                </div>
                <div className="list-details--year">
                    <span>{props.year}</span>
                </div>
            </div>
        </motion.div>
    )
}