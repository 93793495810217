import React from 'react';
import {motion} from 'framer-motion';
import {slideAnimation} from './../util/JsAnimations'

export default function ExperienceDetailed(props) {

    if (props.index == undefined) throw new Error("Index is required");
    if (props.exitExperience == undefined) throw new Error("Exit experience handler is required");

    const experiences = [
        {
            company_logo: "/assets/synapslabs.png",
            role: "Software Developer Intern",
            location: "Durban, KwaZulu Natal, South Africa",
            from: "August, 2022",
            to: "March, 2023",
            about: <span>
                <p>
                    As a Software Developer Intern at Synap Labs, I was immersed in a dynamic and innovative environment where I had the opportunity to gain hands-on experience in both front-end and back-end development. During my internship, I worked on a variety of projects that honed my programming and coding skills, and allowed me to apply theoretical knowledge in practical settings.
                </p>

                <strong>Achievements:</strong><br/>
                <ul>
                    <li>Successfully developed and deployed several web applications that met project requirements and deadlines.</li>
                    <li>Improved the performance of existing applications by implementing efficient coding practices and optimizing database queries.</li>
                    <li>Gained proficiency in multiple programming languages and development frameworks, enhancing my versatility as a developer.</li>
                    <li>My time at Synap Labs was invaluable in solidifying my passion for software development and providing me with the practical skills needed to excel in the IT industry.</li>
                </ul>
            </span>
        },
        {
            company_logo: "/assets/netsurit.png",
            role: "Cyber Security Intern",
            location: "Johannesburg, Gauteng, South Africa",
            from: "March, 2024",
            to: "CURRENT",
            about: <span>
                <p>
                    As a Security Intern at Netsurit IT, I have been immersed in the fast-paced world of IT security, gaining invaluable experience in protecting digital assets and ensuring the integrity of systems. My role involves working with a variety of security tools and technologies, with a particular focus on Microsoft and Azure platforms.
                </p>

                <strong>Achievements:</strong><br/>
                <ul>
                    <li>Contributed to the successful implementation of enhanced security measures that significantly reduced potential vulnerabilities in our systems.</li>
                    <li>Assisted in the development and deployment of security policies that improved overall organizational security posture.</li>
                    <li>Enhanced my proficiency in using Microsoft and Azure security tools, which has been instrumental in effectively managing and securing our IT infrastructure.</li>
                </ul>
            </span>
        }
    ]

    return (
        <motion.section id="experience-detailed">
            <motion.div initial={props.slideAnimation('left').initial} whileInView={props.slideAnimation('left').whileInView} className="go-back" onClick={()=>props.exitExperience()}>
                <i>{"< "}</i>
                Go Back
            </motion.div>
            <div className="experience-detailed--content">
                <div className="experience--details">

                    <motion.div initial={props.slideAnimation('left').initial} whileInView={props.slideAnimation('left').whileInView} className="experience--details--img">
                        <img src={experiences[props.index-1].company_logo} alt={experiences[props.index-1].company_logo} srcset="" />
                    </motion.div>

                    <motion.div initial={props.slideAnimation('down').initial} whileInView={props.slideAnimation('down').whileInView} className="experience--details--role experience--detail">
                        <span>Role:</span>
                        <span>{experiences[props.index-1].role}</span>
                    </motion.div>

                    <motion.div initial={props.slideAnimation('down').initial} whileInView={props.slideAnimation('down').whileInView} className="experience--details--location experience--detail">
                        <span>Location:</span>
                        <span>{experiences[props.index-1].location}</span>
                    </motion.div>

                    <motion.div initial={props.slideAnimation('down').initial} whileInView={props.slideAnimation('down').whileInView} className="experience--details--from experience--detail">
                        <span>From:</span>
                        <span>{experiences[props.index-1].from}</span>
                    </motion.div>
                    <motion.div initial={props.slideAnimation('down').initial} whileInView={props.slideAnimation('down').whileInView} className="experience--details--to experience--detail">
                        <span>To:</span>
                        <span>{experiences[props.index-1].to}</span>
                    </motion.div>

                </div>
                <motion.div initial={props.slideAnimation('right').initial} whileInView={props.slideAnimation('right').whileInView} className="experience--about">
                        <div>About:</div>
                        <span>
                            {experiences[props.index-1].about}
                        </span>
                </motion.div>
            </div>
        </motion.section>
    )
}