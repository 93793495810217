import React from 'react'

export default function ExperienceCard(props) {

    if(props.position == undefined) throw new Error("Position is required");
    if(props.company == undefined) throw new Error("Company is required");
    if(props.onclick == undefined) throw new Error("OnClick handler is required");

    return (
        <div className="experience-card" onClick={()=>props.onclick(props.position+"-"+props.company)} >
            <div className='experience-card--position'>{props.position}</div>
            <div className="experience-card--company">{props.company}</div>
            <div className="experience-card--view">View</div>
        </div>
    )
}