import React, { useState } from 'react';
import './../style/Box.css'
import {motion} from 'framer-motion';

export default function BoxAnimated(props) {

    if(!props.position) throw new Error("position is required");

    return (
        <div className={"box position-"+props.position}>
            <div className="box--squares">
                <div className="box--square"></div>
                <div className="box--square"></div>
            </div>
            <motion.div className="box--text-list">
                <motion.div initial={props.initial} whileInView={props.whileInView} className="box-text">EDUCATED</motion.div>
                <motion.div initial={props.initial} whileInView={props.whileInView} className="box-text">CREATIVE</motion.div>
                <motion.div initial={props.initial} whileInView={props.whileInView} className="box-text">INNOVATIVE</motion.div>
            </motion.div>
        </div>
    )
}