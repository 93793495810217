import React from 'react';
import "./../style/Nav.css";
import {motion} from 'framer-motion';

export default function Nav(props) {

    const [navOpen, setNavOpen] = React.useState(false);
    const [navMobileOpen, setNavMobileOpen] = React.useState(false);
    

    // Detect Mobile
    const isMobile = () => {
        let isDeviceMobile = window.matchMedia("(max-width: 1024px)").matches;

        if(!isDeviceMobile){
            return false;
        }
        else{
            if(navOpen) return false;
            if(!navOpen) return true;
        }
    };

    const buttonClicked = ()=>{
        console.log('Is nav open?',navOpen)
        if (navOpen) setNavOpen(!navOpen);
    };

    return (
        <motion.div className={"nav"+( navOpen? ' nav-open' : ' nav-closed')} initial={props.slideAnimation('up').initial} whileInView={props.slideAnimation('up').whileInView} exit={props.slideAnimation('up').exit}>
            <div id="nav--mobile-button" onClick={()=>{setNavOpen(!navOpen)}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="white" class="bi bi-list" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
                </svg>
            </div>
            <div id="nav-outer" >
                <motion.a 
                onClick={(event)=>buttonClicked()}
                href="#about" className='nav-item slide-right'>About</motion.a>
                <motion.a 
                onClick={(event)=>buttonClicked()}
                href="#education" className='nav-item slide-down'>Education</motion.a>
                <motion.a 
                onClick={(event)=>buttonClicked()}
                href="#experience" className='nav-item slide-left'>Experience</motion.a>
                <motion.a 
                onClick={(event)=>buttonClicked()}
                href="#skills" className='nav-item slide-diagonal'>Skills</motion.a>
                {/* <motion.a 
                onClick={(event)=>buttonClicked()}
                href="#projects" className='nav-item slide-up'>Projects</motion.a> */}
                <motion.a 
                onClick={(event)=>buttonClicked()}
                href="#contact" className='nav-item slide-other-2'>Contact</motion.a>
            </div>
        </motion.div>
    )
}