import React from 'react';
import './../style/Socials.css';
import {motion} from 'framer-motion';

export default function Socials(props) {

    console.log(props.initial);

    return (
        <motion.div className={'social-links '+(props.align_left ? 'social-links--align-left':'social-links--align-right')} 
        initial={props.initial} whileInView={props.whileInView} exit={props.exit}>
            <a href='https://www.instagram.com/jiveshenty/?hl=en' target="_blank" >Instagram</a>
            <a href='https://www.facebook.com/jiveshen.govender/?paipv=0&eav=AfYuM1IqM3uJ0EFFGVPyvxGJ22mJG631rjy5Qm7nbvdr5I3VgI-3h-af_RyrNzH7Ok4&_rdr' target="_blank" >Facebook</a>
            <a href='https://www.linkedin.com/in/jiveshen-govender-3225b5247/' target="_blank" >LinkedIn</a>
            {/* <a href='#' target="_blank">Github</a> */}
        </motion.div>
    )
}