import React from 'react';
import LineBreaker from '../components/LineBreaker';
import ExperienceCard from '../components/ExperienceCard';
import {motion} from 'framer-motion';

export default function ExperienceView(props) {

    if(props.experienceClick == undefined) throw new Error("Experience click handler is required");

        const [isMobile, setIsMobile] = React.useState(false);

        React.useEffect(() => {
            const handleResize = () => {
                setIsMobile(window.innerWidth <= 768);
            };

            handleResize();
            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }, []);


    return (
        <motion.section id="experience">
            <div id="experience--intro">
                <motion.div initial={props.slideAnimation('left').initial} whileInView={props.slideAnimation('left').whileInView} id="experience--img">
                    <div id="experience--img--overlay" className='font-jetbrains'>
                        <span>Experience</span>
                        <span>Experience</span>
                        <span>Experience</span>
                    </div>
                </motion.div>
                <motion.div initial={props.slideAnimation('right').initial} whileInView={props.slideAnimation('right').whileInView} id="experience--text">
                    My journey in the IT field has been marked by continuous growth and dedication. Starting from Greebury Secondary, where I built a strong foundation in IT and mathematics, to my advanced studies at Richfield College, I have always pursued excellence. My roles as a Software Developer Intern at Synap Labs and currently as a Security Intern at Netsurit IT have provided me with valuable hands-on experience. These experiences have not only sharpened my technical skills but also taught me the importance of perseverance and adaptability. Driven by my passion for programming and IT security, I am committed to becoming an experienced Software and Security Analyst. I believe in the power of hard work and continuous learning to overcome challenges and achieve our best selves.
                </motion.div>
            </div>
            <div id="experience--timeline">
                {            
                    isMobile ?    
                    <div id="timeline--years">
                        <div>2022 - 2023</div>
                        <div>2024 - CURRENT</div>
                        {/* <div>2023 - CURRENT</div> */}
                    </div>
                    :
                    <motion.div initial={props.slideAnimation('right').initial} whileInView={props.slideAnimation('right').whileInView} id="timeline--years">
                        <div>2022 - 2023</div>
                        <div>2024 - CURRENT</div>
                        {/* <div>2023 - CURRENT</div> */}
                    </motion.div>
                }
                <LineBreaker />

                {
                    // Determine whether or not to add scroll animations
                    isMobile ?
                    <div  id="timeline--companies">
                        <ExperienceCard onclick={(detail)=>props.experienceClick(detail)} position="Software Developer Intern" company="Synaps Labs" />
                        <ExperienceCard onclick={(detail)=>props.experienceClick(detail)} position="Cyber Security Intern" company="Netsurit" />
                        {/* <ExperienceCard onclick={(detail)=>props.experienceClick(detail)} position="Cyber Security Specialist" company="Space X" /> */}
                    </div>
                    :
                    <motion.div initial={props.slideAnimation('left').initial} whileInView={props.slideAnimation('left').whileInView} id="timeline--companies">
                        <ExperienceCard onclick={(detail)=>props.experienceClick(detail)} position="Software Developer Intern" company="Synaps Labs" />
                        <ExperienceCard onclick={(detail)=>props.experienceClick(detail)} position="Cyber Security Intern" company="Netsurit" />
                        {/* <ExperienceCard onclick={(detail)=>props.experienceClick(detail)} position="Cyber Security Specialist" company="Space X" /> */}
                    </motion.div>
                }
            </div>
        </motion.section>
    )
}