import React, { useState } from 'react';
import './../style/ImageSlider.css';

export default function ImageSlider(props) {

    // Parameter validation
    if (!props.imageUrls) throw new Error("Image slider requires imageUrls list.")

    // Declarations
    const [imageIndexToShow, setImageIndexToShow] = useState(0);
    const images = props.imageUrls;

    // Loop through images to build dots
    let dotsMarkup = [];
    for (let i = 0; i < images.length; i++) {
        dotsMarkup.push(<div className={'image-slider--dot'+(i === 0 ? ' image-slider--dot--active' : '')} key={i}></div>);
    }


    // When the previous image button is clicked
    const previousImage = (event) => {
        // Update the image index state to show
        setImageIndexToShow((prevIndex) => {
            // Calculate the new index, making sure it doesn't go below 0
            let valToReturn = prevIndex <= 0 ? 0 : prevIndex - 1;
            let imageSliderDots = event.target.parentElement.parentElement.parentElement.parentElement.querySelectorAll('.image-slider--dot'); // Get all the dots elements

            // Loop through the dots
            imageSliderDots.forEach((dotElement, index) => {

                // If this dot's index matches the new index
                if (index === valToReturn) {
                    // Make this dot active
                    dotElement.classList.add('image-slider--dot--active');
                } else {
                    // Otherwise make sure it is inactive
                    dotElement.classList.remove('image-slider--dot--active');
                }
            });

            return valToReturn;
        });
    };
    // When the next image button is clicked
    const nextImage = (event) => {
        // Update the image index state
        setImageIndexToShow(prevIndex => {
            // Calculate the new index, making sure it doesn't exceed the last image
            let valToReturn = prevIndex + 1 >= (images.length - 1) ? (images.length - 1) : prevIndex + 1;
            let imageSliderDots = event.target.parentElement.parentElement.parentElement.parentElement.querySelectorAll('.image-slider--dot'); // Get all the dot elements

            // Loop through the dots
            imageSliderDots.forEach((dotElement, index) => {
                // If this dot index matches the new index 
                if (index === valToReturn) {
                    // Make this dot active
                    dotElement.classList.add('image-slider--dot--active');
                } else {
                    // Otherwise make sure it is inactive
                    dotElement.classList.remove('image-slider--dot--active');
                }
            });

            // Return the new index value
            return valToReturn;
        });
    };


    return (
        <div id="image-slider">
            <div id="image-slider--main">
                <div id="image-slider--main--btn-left">
                    <span onClick={previousImage}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-chevron-compact-left" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223"/>
                        </svg>
                    </span>
                </div>
                <div className="img-slider--img">
                    <img src={images[imageIndexToShow]} alt={images[imageIndexToShow]} />
                </div>
                <div id="image-slider--main--btn-right">
                    <span onClick={nextImage}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-chevron-compact-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671"/>
                        </svg>
                    </span>
                </div>
            </div>
            <div id="image-slider--dot-counter">
                {dotsMarkup /* Populate dots for each image in array */}
            </div>
        </div>
    )
}