import React from 'react';
import './../style/ScrollDownBtn.css';

export default function ScrollDownBtn(props) {

    if(!props.scrollToID) throw new Error('ScrollDownBtn requires a scrollToID prop');

    return (
        <div className='scrolldown-btn'>
            <a href={'#'+props.scrollToID}>Scroll Down</a>
        </div>
    )
}