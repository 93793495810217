import React from 'react';
import './../style/Footer.css';

export default function Footer() {
    return (
        <footer>
            <div id="legal">
                &copy; 2024 - Jiveshen Govender | All rights reserved.
            </div>
            <div id="dev-credit">
                Made with L0v3 by <a href='https://theshekharmaharaj.com'><strong>Shekhar Maharaj</strong></a>
            </div>
        </footer>
    )
}