import React from 'react'

export default function SkillListItem(props) {

    if(props.skillText == undefined) throw new Error("SkillListItem requires a skillText prop")

    return (
        <div className="skill-item">
            {props.skillText}
        </div>
    )
}