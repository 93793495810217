import React from 'react';
import Socials from '../islands/Socials';
import './../style/Contact.css';
import { motion } from 'framer-motion';

export default function Contact(props) {
    return (
        <motion.section id="contact">
            <div id="contact--top">

                <motion.div initial={props.slideAnimation('left').initial} whileInView={props.slideAnimation('left').whileInView} id="contact--top-img">
                    <div id="contact--img--overlay">
                        <span>Ring</span>
                        <span>Ring</span>
                        <span>Ring</span>
                    </div>
                </motion.div>
                <div id="contact--top-info">
                    <motion.div initial={props.slideAnimation('down').initial} whileInView={props.slideAnimation('down').whileInView} id="cta-1">
                        <h2>Contact Me</h2>
                    </motion.div>
                    <motion.div initial={props.slideAnimation('down').initial} whileInView={props.slideAnimation('down').whileInView} id="cta-2">
                        Let's work together.
                    </motion.div>

                    <Socials align_left={true} initial={props.slideAnimation('right').initial} whileInView={props.slideAnimation('right').whileInView} exit={props.slideAnimation('right').exit} />

                    <div id="general-contacts">
                        <motion.a initial={props.slideAnimation('right').initial} whileInView={props.slideAnimation('right').whileInView} href="mailto:jiveshengovender10@gmail.com">jiveshengovender10@gmail.com</motion.a>
                        <motion.a initial={props.slideAnimation('right').initial} whileInView={props.slideAnimation('right').whileInView} href="tel:+27634356990">(063)-435-6990</motion.a>
                    </div>
                </div>
            </div>
            <div id="contact--form">

            </div>
        </motion.section>
    )
}