import './App.css';
import Hero from './sections/Hero';
import Nav from './islands/Nav';
import About from './sections/About';
import Education from './sections/Education';
import Experience from './sections/Experience';
import Skills from './sections/Skills';
import Projects from './sections/Projects';
import Contact from './sections/Contact';
import Footer from './islands/Footer';
import {motion,AnimatePresence} from 'framer-motion';
import {slideAnimation} from './util/JsAnimations'

function App() {
  return (
    <>
    <AnimatePresence>
      <Hero />
      <Nav slideAnimation={slideAnimation} />
      <About slideAnimation={slideAnimation} />
      <Education slideAnimation={slideAnimation} />
      <Experience slideAnimation={slideAnimation} />
      <Skills slideAnimation={slideAnimation} />
      {/* <Projects slideAnimation={slideAnimation} /> */}
      <Contact slideAnimation={slideAnimation} />
      <Footer slideAnimation={slideAnimation} />
    </AnimatePresence>
    </>
  );
}

export default App;
