import React from 'react';
import ImageSlider from '../components/ImageSlider';
import Marquee from '../components/Marquee';
import '../style/About.css';
import {motion} from 'framer-motion';

export default function About(props) {
    return (
        <section id='about'>
            <Marquee phrase="WORK HARD. PLAY HARD." direction="left" repeat={true} />
            <div id='about-content'>
                <motion.div initial={props.slideAnimation('left').initial} whileInView={props.slideAnimation('left').whileInView} id="about-content--left">
                    <ImageSlider imageUrls={['/assets/JG_1.jpg', '/assets/JG_2.jpg']} />
                    <div className='font-jetbrains' id="about-content--left--thats-me">
                        That's Me
                    </div>
                    {/* <video src='/assets/cars.mp4' className='font-jetbrains' id="about-content--left--favourites" /> */}
                    {/* <div className='font-jetbrains' id="about-content--left--favourites">
                        <span>Favourite Color: Black</span>
                        <span>Favourite Rapper: Eminem</span>
                        <span>Favourite Wrapper: McDonald's </span>
                        <span>Favourite Food: Breyani</span>
                        <span>Favourite Sport: Soccer</span>
                    </div> */}
                </motion.div>
                <motion.div initial={props.slideAnimation('right').initial} whileInView={props.slideAnimation('right').whileInView} id="about-content--right">
                    <p>
                        I am a hard-working and driven individual who isn't afraid to face a challenge. My passion for programming is at the core of my professional journey, and I take pride in my ability to get the job done effectively. My interest in gaming has been a significant influence, fueling my enthusiasm and dedication to the programming and IT field.
                    </p>
                    <p>
                        My career path has been shaped by my commitment to learning and my desire to continuously improve. I have honed my skills through various projects and experiences, always striving to deepen my understanding and expertise in the industry.
                    </p>
                    <p>
                        Looking ahead, my goal is to become an experienced Software and Security Analyst. I am excited about the future and eager to take on new challenges that will allow me to grow and achieve my professional aspirations.
                    </p>
                </motion.div>
            </div>
        </section>
    )
}